<template>
  <div
    :class="rootClasses"
    class="AllGroupsListISortItem"
    @click="onClick">
    <span>{{ title }}</span>
    <div class="IconWrap">
      <InlineSVG :src="require('@/assets/svg/angle-right.svg')" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'AllGroupsListISortItem',
  emits: ['sorting-click'],
  props: {
    title: {
      type: String,
      required: true,
    },
    sortingName: {
      type: String,
      required: false,
      default: '',
    },
    selectedSorting: {
      type: String,
      required: false,
      default: '',
    },
    sortASC: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        Clickable: this.sortingName,
        SelectedSorting:
          this.sortingName
          && this.sortingName === this.selectedSorting,
        SortingASC: this.sortASC,
      }
    },
  },
  methods: {
    onClick() {
      if (!this.sortingName) {
        return
      }

      this.$emit('sorting-click', this.sortingName)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .AllGroupsListISortItem
    display flex
    align-items center
    flex-grow 1
    flex-basis 0
    position relative
    span
      color $color_grey
      text-transform uppercase
      font-weight bold
    .IconWrap
      display none
    &.Clickable
      cursor pointer
    &.SelectedSorting
      .IconWrap
        flex-center-children()
        box(12px)
        margin-left 6px
        svg
          fill $color_grey
          transform rotateZ(90deg)
      &.SortingASC
        .IconWrap
          svg
            transform rotateZ(-90deg)

</style>
