<template>
  <div class="AllGroups">
    <AllGroupsList />
  </div>
</template>

<script>
import AllGroupsList from '@/components/Admin/AllGroupsList.vue'

export default {
  name: 'AllGroups',
  components: { AllGroupsList },

}
</script>

<style lang="stylus" scoped>
  .AllGroups
    display block
</style>
