<template>
  <div class="AllGroupsList">
    <!-- Sorting -->
    <div class="SortingWrap">
      <AllGroupsListISortItem
        :sortingName="'name'"
        :title="mixWB('GROUP_NAME')"
        :selectedSorting="sorting"
        :sortASC="sortASC"
        @sorting-click="onSortingClick" />
      <AllGroupsListISortItem
        :sortingName="'members'"
        :title="mixWB('MEMBERS')"
        :selectedSorting="sorting"
        :sortASC="sortASC"
        @sorting-click="onSortingClick" />
      <AllGroupsListISortItem
        :sortingName="'createdAt.seconds'"
        :title="mixWB('CREATED')"
        :selectedSorting="sorting"
        :sortASC="sortASC"
        @sorting-click="onSortingClick" />
      <AllGroupsListISortItem
        :title="mixWB('LINKS')" />
    </div>

    <!-- Groups -->
    <div class="ListWrap">
      <div
        class="ListItem"
        v-for="group in sortedGroups"
        :key="group.id">
        <span>{{ group.name }}</span>
        <span>
          {{
            group.totalMembers === 1
              ? mixWB('1_MEMBER')
              : mixWB('X_MEMBERS', [group.totalMembers])
          }}
        </span>
        <span>{{ mixGetDate(group.createdAt, 'LL') }}</span>
        <span
          class="CopyURL"
          @click="onCopyURLClick(group.id)">
          {{ copiedGroupID === group.id ? mixWB('LINK_COPIED') : mixWB('COPY_LINK') }}
        </span>
      </div>
    </div>

    <!-- Input field to copy URL from -->
    <input
      class="HiddenURLInput"
      type="text"/>
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import AllGroupsListISortItem from './AllGroupsListISortItem'

export default {
  name: 'AllGroupsList',
  data() {
    return {
      sorting: 'createdAt.seconds',
      sortASC: false,
      copiedGroupID: '',
    }
  },
  computed: {
    ...mapGetters([
      'allGroups',
      'allGroupsLoaded',
    ]),
    sortedGroups() {
      if (!this.allGroupsLoaded) {
        return []
      }

      if (this.sortASC) {
        return sortBy(this.allGroups, [this.sorting])
      }

      return sortBy(this.allGroups, [this.sorting]).reverse()
    },
  },
  methods: {
    onSortingClick(sortingName) {
      if (sortingName === this.sorting) {
        this.sortASC = !this.sortASC
        return
      }

      this.sorting = sortingName
      this.sortASC = false
    },
    onCopyURLClick(groupID) {
      const tempInput = document.createElement('input')
      document.body.append(tempInput)
      tempInput.value = `${
        window.location.origin
      }/start-link/${
        groupID
      }`
      tempInput.select()
      document.execCommand('copy')
      tempInput.remove()

      this.copiedGroupID = groupID
      setTimeout(() => {
        if (this.copiedGroupID === groupID) {
          this.copiedGroupID = ''
        }
      }, 1000)
    },
  },
  components: {
    AllGroupsListISortItem,
  },
}
</script>

<style lang="stylus" scoped>
  .AllGroupsList
    display block

  .ListItem
    padding 10px 0px
    border-bottom 1px solid $color_grey_lighter
    display flex
    > span
      display block
      flex-grow 1
      flex-basis 0

  .SortingWrap
    display flex
    border-bottom 1px solid $color_grey_lighter
    padding-bottom 10px

  .CopyURL
    text-decoration underline
    cursor pointer

  .HiddenURLInput
    display none
</style>
